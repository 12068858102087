import React, { useEffect, useState, MouseEvent, useRef } from "react";
import CSS from "csstype";
import { Router, RouteComponentProps } from "@reach/router";
import { navigate } from "gatsby";
import "react-quill/dist/quill.snow.css";
import { awardHost } from "../utils";
import "../styles/css/ae.css";
/*--------------------------------------------------------------------
|                             LAYOUT
---------------------------------------------------------------------*/
const Layout: React.FC<{}> = ({ children }) => {
  const style = {
    maxWidth: "1140px",
    margin: "0 auto"
  };
  return <div style={style}>{children}</div>;
};

/*
  {
    "award": "Deutscher Digital Award",
    "platzierung": "Bronze",
    "kategorie": "Digital for Goods - Products & Services",
    "projekt": "Astra - St. Pauli Adventskalender",
    "jahr": 2021
  },
*/

type AwardObj = {
  award: string;
  platzierung: string;
  kategorie: string;
  projekt: string;
  jahr: number;
  id: string;
};
/*--------------------------------------------------------------------
|                            EDIT FORM
---------------------------------------------------------------------*/
let EditForm: any = (props: any) => {
  const [currAward, setCurrAward] = useState(props.awardData[props.currItemId]);

  const setStateFromEditor = (event: React.FormEvent<HTMLInputElement>) => {
    const { name, value } = event.currentTarget;
    switch (name) {
      case name:
        setCurrAward((prevAward: AwardObj) => ({
          ...prevAward,
          [name]: value
        }));
        break;
      default:
        break;
    }
  };

  // useEffect(() => {
  //   console.log(currAward);
  // }, [currAward]);

  return (
    <form className="edit-award-form">
      <div className="ae-input-control">
        <label htmlFor="">Award</label>
        <input
          type="text"
          name="award"
          value={currAward.award}
          onChange={setStateFromEditor}
        />
      </div>
      <div className="ae-input-control">
        <label htmlFor="">Platzierung</label>
        <input
          type="text"
          name="platzierung"
          value={currAward.platzierung}
          onChange={setStateFromEditor}
        />
      </div>
      <div className="ae-input-control">
        <label htmlFor="">Kategorie</label>
        <input
          type="text"
          name="kategorie"
          value={currAward.kategorie}
          onChange={setStateFromEditor}
        />
      </div>
      <div className="ae-input-control">
        <label htmlFor="">Projekt</label>
        <input
          type="text"
          name="projekt"
          value={currAward.projekt}
          onChange={setStateFromEditor}
        />
      </div>
      <div className="ae-input-control">
        <label htmlFor="">Jahr</label>
        <input
          type="number"
          name="jahr"
          value={currAward.jahr}
          onChange={setStateFromEditor}
        />
      </div>

      <div className="ae-input-control">
        <div className="btn-control">
          <button onClick={props.onRejectDel}>Cancle</button>
          <button
            onClick={(evt: any) => {
              evt.preventDefault();
              props.saveCurrAward(currAward);
            }}
          >
            Save
          </button>
        </div>
      </div>
    </form>
  );
};

/*--------------------------------------------------------------------
|                            PROFILE
---------------------------------------------------------------------*/
let Profile = (props: RouteComponentProps) => {
  const [awardData, setAwardData] = useState([
    {
      award: "",
      platzierung: "",
      kategorie: "",
      projekt: "",
      jahr: 0,
      id: ""
    }
  ]);
  const [showModal, setShowModal] = useState(false);
  const [currItemId, setCurrItemId] = useState(0);
  const [modalType, setModalType] = useState("");
  const [searchFilter, setSearchFilter] = useState("");
  const [showMsg, setShowMsg] = useState(false);
  const mainRef = useRef<HTMLDivElement>(null);

  /* ON DELETE */
  const onDeleteItem = (evt: MouseEvent<HTMLSpanElement>) => {
    const currIdx: any = evt.currentTarget.dataset.idx;
    setCurrItemId(parseInt(currIdx));
    setModalType("del");
    setTimeout(() => {
      setShowModal(true);
    });
  };
  /* ON CONFIRM DELETE */
  const onConfirmDel = () => {
    setAwardData(prevData => {
      return prevData
        .filter((_: AwardObj, idx: number) => idx !== currItemId)
        .map((item: AwardObj, idx: number) => ({
          ...item,
          id: idx.toString()
        }));
    });
    setCurrItemId(0);
    setModalType("");
    setTimeout(() => {
      setShowModal(false);
    });
    //console.log(currItemId);
  };
  /* ON REJECT DELETE */
  const onRejectDel = () => {
    setCurrItemId(0);
    setModalType("");
    setTimeout(() => {
      setShowModal(false);
    });
  };
  /* ON EDIT */
  const onEdit = (evt: any) => {
    const currIdx: any = evt.currentTarget.dataset.idx;
    setCurrItemId(parseInt(currIdx));
    setModalType("edit");
    setTimeout(() => {
      setShowModal(true);
    });
  };
  /* ON SAVE CURRENT AWARD */
  const saveCurrAward = (currentAward: AwardObj) => {
    setAwardData((prevData: Array<AwardObj>) =>
      prevData.map((item, idx) => {
        if (idx === currItemId) {
          return currentAward;
        }
        return item;
      })
    );
    setTimeout(() => {
      onRejectDel();
    }, 1);
  };
  /* ON ADD NEW ITEM */
  const addAwardItem = (evt: any) => {
    evt.preventDefault();
    setAwardData((prevData: Array<AwardObj>) => {
      const counter = prevData.length;
      return [
        {
          award: "",
          platzierung: "",
          kategorie: "",
          projekt: "Please edit me!",
          jahr: 0,
          id: counter.toString()
        },
        ...prevData
      ].map((item: AwardObj, idx: number) => ({ ...item, id: idx.toString() }));
    });
    // setTimeout(() => {
    //   if (mainRef.current) {
    //     mainRef.current.scroll({
    //       top: 10000000,
    //       behavior: "smooth"
    //     });
    //   }
    // }, 250);
  };
  /* ON SAVE AWARDS TO FILE  */
  const saveAwards2File = async (evt: MouseEvent<HTMLButtonElement>) => {
    evt.preventDefault();
    try {
      const response = await fetch(awardHost + "awards.php", {
        method: "PUT",
        body: JSON.stringify(awardData),
        headers: {
          "Content-Type": "application/json"
        }
      });
      const json = await response.json();
      setShowMsg(true);
      //console.log(json);
      setTimeout(() => {
        location.reload();
      }, 1000);
    } catch (err) {
      console.log(err);
    }
  };

  const renderModalContent = (type: string) => {
    switch (type) {
      case "del":
        return (
          <div className="ae-modal-inner">
            <p>
              Möchtest du den Eintrag
              <br />
              <span
                style={{
                  color: "#ff0064",
                  fontWeight: "bold"
                }}
              >
                {awardData[currItemId].projekt}
              </span>
              <br />
              wirklich löschen?
            </p>

            <div className="btn-container">
              <button onClick={onConfirmDel}>Ja</button>
              <button onClick={onRejectDel}>Nein</button>
            </div>
          </div>
        );
      case "edit":
        return (
          <div className="ae-modal-inner">
            <EditForm
              onRejectDel={onRejectDel}
              saveCurrAward={saveCurrAward}
              currItemId={currItemId}
              awardData={awardData}
            />
          </div>
        );
      default:
        return null;
    }
  };

  const onChangeSearchFilter = (event: React.FormEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    setSearchFilter(value);
  };

  const renderAward = (awards: Array<AwardObj>) => {
    return (
      <>
        {showModal ? (
          <div className="ae-modal-container">
            {renderModalContent(modalType)}
          </div>
        ) : null}

        <ul className="ae-award-list">
          {searchFilter.length > 2
            ? awards
                .filter(
                  award =>
                    award.award.includes(searchFilter) ||
                    award.platzierung.includes(searchFilter) ||
                    award.kategorie.includes(searchFilter) ||
                    award.projekt.includes(searchFilter) ||
                    award.jahr.toString(10).includes(searchFilter)
                )
                .map((award, idx) => (
                  <li key={idx}>
                    {/* <span>{award.id}</span>
                    <span>|</span> */}

                    <span>{award.award}</span>
                    <span>|</span>
                    <span>{award.platzierung}</span>
                    <span>|</span>
                    <span>{award.kategorie}</span>
                    <span>|</span>
                    <span>{award.projekt}</span>
                    <span>|</span>
                    <span>{award.jahr}</span>
                    <div>
                      <span
                        className="edit-btn"
                        onClick={onEdit}
                        data-idx={award.id}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                        >
                          <path d="M1.439 16.873l-1.439 7.127 7.128-1.437 16.873-16.872-5.69-5.69-16.872 16.872zm4.702 3.848l-3.582.724.721-3.584 2.861 2.86zm15.031-15.032l-13.617 13.618-2.86-2.861 10.825-10.826 2.846 2.846 1.414-1.414-2.846-2.846 1.377-1.377 2.861 2.86z" />
                        </svg>
                      </span>
                      <span
                        className="del-btn"
                        onClick={onDeleteItem}
                        data-idx={award.id}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                        >
                          <path d="M9 19c0 .552-.448 1-1 1s-1-.448-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10zm4 0c0 .552-.448 1-1 1s-1-.448-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10zm4 0c0 .552-.448 1-1 1s-1-.448-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10zm5-17v2h-20v-2h5.711c.9 0 1.631-1.099 1.631-2h5.315c0 .901.73 2 1.631 2h5.712zm-3 4v16h-14v-16h-2v18h18v-18h-2z" />
                        </svg>
                      </span>
                    </div>
                  </li>
                ))
            : awards.map((award, idx) => (
                <li key={idx}>
                  {/* <span>{award.id}</span>
                  <span>|</span> */}

                  <span>{award.award}</span>
                  <span>|</span>
                  <span>{award.platzierung}</span>
                  <span>|</span>
                  <span>{award.kategorie}</span>
                  <span>|</span>
                  <span>{award.projekt}</span>
                  <span>|</span>
                  <span>{award.jahr}</span>
                  <div>
                    <span
                      className="edit-btn"
                      onClick={onEdit}
                      data-idx={award.id}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                      >
                        <path d="M1.439 16.873l-1.439 7.127 7.128-1.437 16.873-16.872-5.69-5.69-16.872 16.872zm4.702 3.848l-3.582.724.721-3.584 2.861 2.86zm15.031-15.032l-13.617 13.618-2.86-2.861 10.825-10.826 2.846 2.846 1.414-1.414-2.846-2.846 1.377-1.377 2.861 2.86z" />
                      </svg>
                    </span>
                    <span
                      className="del-btn"
                      onClick={onDeleteItem}
                      data-idx={award.id}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                      >
                        <path d="M9 19c0 .552-.448 1-1 1s-1-.448-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10zm4 0c0 .552-.448 1-1 1s-1-.448-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10zm4 0c0 .552-.448 1-1 1s-1-.448-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10zm5-17v2h-20v-2h5.711c.9 0 1.631-1.099 1.631-2h5.315c0 .901.73 2 1.631 2h5.712zm-3 4v16h-14v-16h-2v18h18v-18h-2z" />
                      </svg>
                    </span>
                  </div>
                </li>
              ))}
        </ul>
      </>
    );
  };

  useEffect(() => {
    const getData = async (formData: FormData) => {
      const response = await fetch(awardHost + "awards.php", {
        method: "POST",
        body: formData
      });
      const json = await response.json();
      return json;
    };

    const hash = sessionStorage.getItem("hash");
    if (hash) {
      const formData: FormData = new FormData();
      formData.append("hash", hash);
      getData(formData).then(data => {
        //console.log(data.data);
        // const mappedData = data.map((item: AwardObj, idx: number) => ({
        //   ...item,
        //   id: idx.toString()
        // }));
        setAwardData(data);
        // setTimeout(() => {
        //   console.log(awardData); //
        // }, 3000);
      });
    } else {
      navigate("/ingolf");
    }
  }, []);

  return (
    <div className="ae-container">
      <header>
        <h1>Awards Editor</h1>
        <button onClick={saveAwards2File}>Save</button>
      </header>
      <div className={showMsg ? "message-container show" : "message-container"}>
        <div className="message">Awards successfully saved</div>
      </div>
      <main ref={mainRef}>
        <div className="search-container">
          <div className="ae-input-control">
            <input
              type="text"
              name="search"
              placeholder="Suche (Groß- und Kleinschreibung beachten)"
              value={searchFilter}
              onChange={onChangeSearchFilter}
            />
          </div>
        </div>
        {awardData && awardData.length ? (
          renderAward(awardData)
        ) : (
          <div>Loading...</div>
        )}
      </main>
      <footer>
        <button onClick={addAwardItem}>Add</button>
      </footer>
    </div>
  );
};

/*--------------------------------------------------------------------
|                             LOGIN
---------------------------------------------------------------------*/
let Login = (props: RouteComponentProps) => {
  const formStyle: CSS.Properties = {
    display: "flex",
    flexDirection: "column",
    maxWidth: "320px",
    justifyContent: "center",
    alignItems: "center",
    margin: "0 auto"
  };
  const generigStylesLabel: CSS.Properties = {
    width: "100%",
    margin: "0 0 12px 0"
  };
  const generigStylesInput: CSS.Properties = {
    width: "100%",
    margin: "0 0 12px 0",
    padding: "8px 12px",
    boxSizing: "border-box"
  };
  const btnStyle = {
    padding: "8px 12px",
    margin: "14px 0 0 0",
    width: "100%",
    cursor: "pointer"
  };
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [msgIsVisible, setMsgVisibility] = useState(false);
  const msg = "Nope, so wird das nix.";

  const handleUsername = (evt: any) => {
    //console.log(evt.target.value);
    setUsername(evt.target.value);
  };

  const handlePassword = (evt: any) => {
    setPassword(evt.target.value);
  };

  const handleSubmit = (evt: any) => {
    evt.preventDefault();
    setMsgVisibility(false);
    const formData: FormData = new FormData(evt.target);
    const authenticate = async () => {
      const response = await fetch(awardHost + "awards.php", {
        method: "post",
        body: formData
      });
      const json = await response.json();
      return json;
    };
    authenticate().then(data => {
      if (data.auth) {
        sessionStorage.setItem("hash", data.hash);
        navigate("/ingolf/edit-awards");
      } else {
        setMsgVisibility(true);
      }
    });
  };

  return (
    <div style={{ margin: "0 auto" }}>
      <h1 style={{ maxWidth: "320px", margin: "26% auto 18px" }}>Login</h1>
      {msgIsVisible ? (
        <div
          style={{
            maxWidth: "320px",
            margin: "0 auto 16px",
            boxSizing: "border-box",
            padding: "8px 12px",
            background: "tomato",
            border: "1px solid #b9383c",
            color: "white",
            borderRadius: "4px",
            textAlign: "center"
          }}
        >
          {msg}
        </div>
      ) : null}
      <form style={formStyle} onSubmit={handleSubmit}>
        <label style={generigStylesLabel}>Username</label>
        <input
          style={generigStylesInput}
          type="text"
          name="username"
          value={username}
          onChange={handleUsername}
        />
        <label style={generigStylesLabel}>Password</label>
        <input
          style={generigStylesInput}
          type="password"
          name="password"
          value={password}
          onChange={handlePassword}
        />
        <input style={btnStyle} type="submit" value="Log In" />
      </form>
    </div>
  );
};

/*--------------------------------------------------------------------
|                               APP
---------------------------------------------------------------------*/
export default function App() {
  return (
    <Layout>
      <Router basepath="/ingolf">
        <Profile path="/edit-awards" />
        <Login path="/" />
      </Router>
    </Layout>
  );
}
